import de from '~/i18n/de.yaml';
import en from '~/i18n/en.yaml';

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'de-CH',
  messages: {
    de,
    en
  }
}));
